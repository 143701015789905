import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, Form, Modal, Radio, Select, Space, Switch } from 'antd';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useMutationSetConfigurationAlarmDefault from '~/apollo/hooks/configurationAlarm/useMutationSetConfigurationAlarmDefault';
import useMutationSetConfigurationAlarmOverride from '~/apollo/hooks/configurationAlarm/useMutationSetConfigurationAlarmOverride';
import ConfigurationToggleFormItem from '~/components/forms/ConfigurationToggleFormItem';
import { DEFAULT_ALARM_CONFIGURATION } from '~/config/defaults';
import useAgentsContext from '~/context/useAgentsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { ALARM_LEVEL, ALARM_TYPE } from '~/types/alarm';
import { CONFIGURATION_SCOPE_LEVEL, type ConfigurationClientScope } from '~/types/configuration';
import {
  ALARM_CONFIGURATION_AUTHORITY,
  AlarmConfiguration,
  AlarmConfigurationOverride,
} from '~/types/configurationAlarm';
import type { ModalProps } from '~/types/modal';
import doesValueExist from '~/utils/configurations/doesValueExist';
import getConfigurationScope from '~/utils/configurations/getConfigurationScope';
import notification from '~/utils/notification';
import getYesOrNo from '~/utils/parse/getYesOrNo';

const TopGridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  ${theme.medias.lteSmall} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const BaseGridDiv = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  ${theme.medias.lteSmall} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const BooleansGridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  ${theme.medias.lteSmall} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const BottomActionsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ALARM_LEVEL_OPTIONS = Object.values(ALARM_LEVEL).map((level) => ({
  value: level,
  label: level,
}));

const ALARM_AUTHORITY_OPTIONS = Object.values(ALARM_CONFIGURATION_AUTHORITY).map((authority) => ({
  value: authority,
  label: authority,
}));

type FormValues = Partial<AlarmConfiguration>;

interface EnabledFormValues {
  level: boolean;
  authority: boolean;
  notify_sms: boolean;
  notify_voice_call: boolean;
  activate_video: boolean;
  vest_feedback: boolean;
  can_assign: boolean;
  can_view_other_carriers: boolean;
  can_view_teamleader: boolean;
  can_dismiss_dashboard: boolean;
  can_dismiss_teamleader: boolean;
  can_dismiss_carrier: boolean;
  can_cancel: boolean;
  trigger_parameters: boolean;
}

const COMING_SOON_KEYS: Set<keyof EnabledFormValues> = new Set([
  'vest_feedback',
  'can_view_other_carriers',
  'can_view_teamleader',
  'can_dismiss_teamleader',
  'can_dismiss_carrier',
  'trigger_parameters',
]);

function getEnabledFormValues({
  isGlobal,
  existingConfiguration,
}: {
  isGlobal: boolean;
  existingConfiguration: AlarmConfiguration | undefined;
}): EnabledFormValues {
  return {
    level: isGlobal || doesValueExist(existingConfiguration?.level),
    authority: isGlobal || doesValueExist(existingConfiguration?.authority),
    notify_sms: isGlobal || doesValueExist(existingConfiguration?.notify_sms),
    notify_voice_call: isGlobal || doesValueExist(existingConfiguration?.notify_voice_call),
    activate_video: isGlobal || doesValueExist(existingConfiguration?.activate_video),
    vest_feedback: isGlobal || doesValueExist(existingConfiguration?.vest_feedback),
    can_assign: isGlobal || doesValueExist(existingConfiguration?.can_assign),
    can_view_other_carriers:
      isGlobal || doesValueExist(existingConfiguration?.can_view_other_carriers),
    can_view_teamleader: isGlobal || doesValueExist(existingConfiguration?.can_view_teamleader),
    can_dismiss_dashboard: isGlobal || doesValueExist(existingConfiguration?.can_dismiss_dashboard),
    can_dismiss_teamleader:
      isGlobal || doesValueExist(existingConfiguration?.can_dismiss_teamleader),
    can_dismiss_carrier: isGlobal || doesValueExist(existingConfiguration?.can_dismiss_carrier),
    can_cancel: isGlobal || doesValueExist(existingConfiguration?.can_cancel),
    trigger_parameters: isGlobal || doesValueExist(existingConfiguration?.trigger_parameters),
  };
}

export interface AlarmConfigurationAddEditModalProps {
  isEdit: boolean;
  itemScope: ConfigurationClientScope;
  itemId: string | undefined;
  selectedAlarmType: ALARM_TYPE;
  alarmConfigurations: AlarmConfiguration[];
  refetchAlarmConfigurations: () => void;
}

type BooleanFormItem = {
  label: string;
  tooltipContent?: string;
  key: keyof EnabledFormValues;
};

const AlarmConfigurationAddEditModal = memo(
  ({
    isOpen,
    onClose,
    isEdit,
    itemScope,
    itemId,
    selectedAlarmType,
    alarmConfigurations,
    refetchAlarmConfigurations,
  }: ModalProps & AlarmConfigurationAddEditModalProps) => {
    const { currentCompany } = useCompany();
    const { currentSubsidiary } = useSubsidiary();

    const [selectedAgentId, setSelectedAgentId] = useState<string | null>(
      itemScope === 'agent' ? itemId || null : null,
    );

    const [selectedScope, setSelectedScope] = useState<CONFIGURATION_SCOPE_LEVEL>(
      getConfigurationScope(itemScope),
    );

    const isGlobal = selectedScope === CONFIGURATION_SCOPE_LEVEL.platform;

    const { agents, isLoading: isAgentsContextLoading } = useAgentsContext();

    const {
      setConfigurationAlarmDefault,
      isLoading: isMutationSetConfigurationAlarmDefaultLoading,
    } = useMutationSetConfigurationAlarmDefault();

    const {
      setConfigurationAlarmOverride,
      isLoading: isMutationSetConfigurationAlarmOverrideLoading,
    } = useMutationSetConfigurationAlarmOverride();

    const isLoading =
      isAgentsContextLoading ||
      isMutationSetConfigurationAlarmDefaultLoading ||
      isMutationSetConfigurationAlarmOverrideLoading;

    const existingGlobalPlatformConfig = useMemo(
      () =>
        alarmConfigurations.find(
          (config) => (config as AlarmConfigurationOverride)?.scope?.id === undefined,
        ),
      [alarmConfigurations],
    );

    const existingCurrentCompanyConfig = useMemo(
      () =>
        alarmConfigurations.find(
          (config) => (config as AlarmConfigurationOverride)?.scope?.id === currentCompany?.id,
        ),
      [alarmConfigurations, currentCompany?.id],
    );

    const existingCurrentSubsidiaryConfig = useMemo(
      () =>
        alarmConfigurations.find(
          (config) => (config as AlarmConfigurationOverride)?.scope?.id === currentSubsidiary?.id,
        ),
      [alarmConfigurations, currentSubsidiary?.id],
    );

    const existingConfiguration = useMemo(() => {
      switch (selectedScope) {
        case CONFIGURATION_SCOPE_LEVEL.company:
          return existingCurrentCompanyConfig;
        case CONFIGURATION_SCOPE_LEVEL.subsidiary:
          return existingCurrentSubsidiaryConfig;
        case CONFIGURATION_SCOPE_LEVEL.agent:
          return alarmConfigurations.find(
            (config) => (config as AlarmConfigurationOverride)?.scope?.id === selectedAgentId,
          );
        case CONFIGURATION_SCOPE_LEVEL.platform:
        default:
          return existingGlobalPlatformConfig;
      }
    }, [
      alarmConfigurations,
      existingGlobalPlatformConfig,
      existingCurrentCompanyConfig,
      existingCurrentSubsidiaryConfig,
      selectedAgentId,
      selectedScope,
    ]);

    const [formValues, setFormValues] = useState<FormValues>(
      merge(
        {
          activated: existingConfiguration?.activated ?? true,
          level: existingConfiguration?.level ?? ALARM_LEVEL.Critical,
          authority: existingConfiguration?.authority ?? ALARM_CONFIGURATION_AUTHORITY.Backend,
          notify_sms: existingConfiguration?.notify_sms ?? false,
          notify_voice_call: existingConfiguration?.notify_voice_call ?? false,
          activate_video: existingConfiguration?.activate_video ?? false,
          vest_feedback: existingConfiguration?.vest_feedback ?? false,
          can_assign: existingConfiguration?.can_assign ?? false,
          can_view_other_carriers: existingConfiguration?.can_view_other_carriers ?? false,
          can_view_teamleader: existingConfiguration?.can_view_teamleader ?? false,
          can_dismiss_dashboard: existingConfiguration?.can_dismiss_dashboard ?? false,
          can_dismiss_teamleader: existingConfiguration?.can_dismiss_teamleader ?? false,
          can_dismiss_carrier: existingConfiguration?.can_dismiss_carrier ?? false,
          can_cancel: existingConfiguration?.can_cancel ?? false,
          trigger_parameters: existingConfiguration?.trigger_parameters ?? '{}',
        },
        existingConfiguration,
      ),
    );

    useEffect(() => {
      if (existingConfiguration) {
        setFormValues(existingConfiguration);
      }
    }, [existingConfiguration]);

    const agentOptions = useMemo(
      () =>
        orderBy(
          agents.map((agent) => {
            const label = `${agent.completeName}${agent.email ? ` (${agent.email})` : ''}`;
            return {
              value: agent.id,
              label,
              labelNormalized: label.toLocaleLowerCase(),
              disabled: alarmConfigurations.some(
                (config) => (config as AlarmConfigurationOverride)?.scope?.id === agent.id,
              ),
            };
          }),
          ['labelNormalized'],
          ['asc'],
        ),
      [agents, alarmConfigurations],
    );

    const [enabledFormValues, setEnabledFormValues] = useState<EnabledFormValues>(
      getEnabledFormValues({ isGlobal, existingConfiguration }),
    );

    useEffect(() => {
      setEnabledFormValues(getEnabledFormValues({ isGlobal, existingConfiguration }));
    }, [isGlobal, existingConfiguration]);

    const handleSubmit = useCallback(async () => {
      try {
        const formValuesConfiguration: AlarmConfiguration = {
          activated: formValues.activated || false,
          level: enabledFormValues.level ? formValues.level ?? null : null,
          authority: enabledFormValues.authority ? formValues.authority ?? null : null,
          notify_sms: enabledFormValues.notify_sms ? formValues.notify_sms ?? null : null,
          notify_voice_call: enabledFormValues.notify_voice_call
            ? formValues.notify_voice_call ?? null
            : null,
          activate_video: enabledFormValues.activate_video
            ? formValues.activate_video ?? null
            : null,
          vest_feedback: enabledFormValues.vest_feedback ? formValues.vest_feedback ?? null : null,
          can_assign: enabledFormValues.can_assign ? formValues.can_assign ?? null : null,
          can_view_other_carriers: enabledFormValues.can_view_other_carriers
            ? formValues.can_view_other_carriers ?? null
            : null,
          can_view_teamleader: enabledFormValues.can_view_teamleader
            ? formValues.can_view_teamleader ?? null
            : null,
          can_dismiss_dashboard: enabledFormValues.can_dismiss_dashboard
            ? formValues.can_dismiss_dashboard ?? null
            : null,
          can_dismiss_teamleader: enabledFormValues.can_dismiss_teamleader
            ? formValues.can_dismiss_teamleader ?? null
            : null,
          can_dismiss_carrier: enabledFormValues.can_dismiss_carrier
            ? formValues.can_dismiss_carrier ?? null
            : null,
          can_cancel: enabledFormValues.can_cancel ? formValues.can_cancel ?? null : null,
          trigger_parameters: enabledFormValues.trigger_parameters
            ? formValues.trigger_parameters || '{}'
            : null,
        };
        if (isGlobal) {
          await setConfigurationAlarmDefault({
            variables: {
              name: selectedAlarmType,
              configuration: merge(DEFAULT_ALARM_CONFIGURATION, formValuesConfiguration),
            },
          });
        } else {
          await setConfigurationAlarmOverride({
            name: selectedAlarmType,
            configuration: formValuesConfiguration,
            companyId:
              selectedScope === CONFIGURATION_SCOPE_LEVEL.company ? currentCompany?.id : undefined,
            subsidiaryId:
              selectedScope === CONFIGURATION_SCOPE_LEVEL.subsidiary
                ? currentSubsidiary?.id
                : undefined,
            carrierId:
              selectedScope === CONFIGURATION_SCOPE_LEVEL.agent
                ? selectedAgentId || undefined
                : undefined,
          });
        }
        onClose();
        await refetchAlarmConfigurations();
        const notificationDescriptionTranslation = isEdit
          ? i18n.t('configurationsGeneric.configurationUpdatedSuccess')
          : i18n.t('configurationsGeneric.configurationCreatedSuccess');
        notification.success({
          message: i18n.t('common.success'),
          description: notificationDescriptionTranslation,
        });
      } catch (error) {
        const errorMessage = (error as any)?.message;
        notification.error({
          message:
            errorMessage === 'exactly 1 entity needs to be defined'
              ? i18n.t('configurationsGeneric.youNeedToSelectAnAgentToContinue')
              : errorMessage || i18n.t('common.error'),
        });
      }
    }, [
      formValues,
      enabledFormValues,
      isGlobal,
      refetchAlarmConfigurations,
      onClose,
      isEdit,
      setConfigurationAlarmDefault,
      selectedAlarmType,
      setConfigurationAlarmOverride,
      selectedScope,
      currentCompany?.id,
      currentSubsidiary?.id,
      selectedAgentId,
    ]);

    const enabledCount = useMemo(
      () => Object.values(enabledFormValues).filter(Boolean).length,
      [enabledFormValues],
    );

    const overridesCountTranslation = ` (${i18n.t('configurationsGeneric.overrides', {
      count: enabledCount,
    })})`;

    const topBooleanItems: BooleanFormItem[] = [
      {
        label: i18n.t('alarmConfigurations.activateVideo'),
        tooltipContent: i18n.t<string>('alarmConfigurations.tooltips.activateVideo'),
        key: 'activate_video',
      },
      {
        label: i18n.t('alarmConfigurations.notifySms'),
        tooltipContent: i18n.t<string>('alarmConfigurations.tooltips.notifySms'),
        key: 'notify_sms',
      },
      {
        label: i18n.t('alarmConfigurations.notifyVoiceCall'),
        tooltipContent: i18n.t<string>('alarmConfigurations.tooltips.notifyVoiceCall'),
        key: 'notify_voice_call',
      },
      {
        label: i18n.t('alarmConfigurations.vestFeedback'),
        key: 'vest_feedback',
      },
    ];

    const canViewBooleanItems: BooleanFormItem[] = [
      {
        label: i18n.t('alarmConfigurations.canViewTeamLeader'),
        key: 'can_view_teamleader',
      },
      {
        label: i18n.t('alarmConfigurations.canViewOtherCarriers'),
        key: 'can_view_other_carriers',
      },
    ];

    const canDismissBooleanItems: BooleanFormItem[] = [
      {
        label: i18n.t('alarmConfigurations.canDismissDashboard'),
        key: 'can_dismiss_dashboard',
      },
      {
        label: i18n.t('alarmConfigurations.canDismissTeamLeader'),
        key: 'can_dismiss_teamleader',
      },
      {
        label: i18n.t('alarmConfigurations.canDismissCarrier'),
        key: 'can_dismiss_carrier',
      },
    ];

    const bottomBooleanItems: BooleanFormItem[] = [
      {
        label: i18n.t('alarmConfigurations.canCancel'),
        key: 'can_cancel',
      },
      {
        label: i18n.t('alarmConfigurations.canAssign'),
        key: 'can_assign',
      },
    ];

    /*
    const hasTriggerParametersError = useMemo(() => {
      if (!enabledFormValues?.trigger_parameters) {
        return false;
      }
      try {
        JSON.parse(formValues?.trigger_parameters || '{}');
        return false;
      } catch (error) {
        return true;
      }
    }, [enabledFormValues?.trigger_parameters, formValues?.trigger_parameters]);
    */

    const createConfigurationTranslation = isGlobal
      ? i18n.t('configurationsGeneric.editGlobalConfiguration')
      : i18n.t('configurationsGeneric.editConfigurationOverride');

    const modalTitle = isEdit
      ? createConfigurationTranslation
      : i18n.t('configurationsGeneric.createConfiguration');

    const renderBooleanFormItem = (booleanFormItems: BooleanFormItem[]) => (
      <BooleansGridDiv>
        {booleanFormItems.map((booleanFormItem) => {
          const isComingSoon = COMING_SOON_KEYS.has(booleanFormItem.key);
          return (
            <ConfigurationToggleFormItem
              key={booleanFormItem.key}
              label={booleanFormItem.label}
              tooltipContent={
                isComingSoon ? i18n.t('common.comingSoon') : booleanFormItem.tooltipContent
              }
              checked={enabledFormValues[booleanFormItem.key]}
              onChange={(isChecked) => {
                setEnabledFormValues((prev) => ({ ...prev, [booleanFormItem.key]: isChecked }));
                setFormValues((prev) => ({ ...prev, [booleanFormItem.key]: isChecked }));
              }}
              isGlobal={isGlobal}
              disabled={isComingSoon}
            >
              <Switch
                checked={formValues?.[booleanFormItem.key] === true || undefined}
                disabled={isComingSoon}
                loading={isLoading}
                onChange={(checked) => {
                  setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    [booleanFormItem.key]: checked,
                  }));
                }}
              />
            </ConfigurationToggleFormItem>
          );
        })}
      </BooleansGridDiv>
    );

    return (
      <Modal
        title={`${modalTitle} (${i18n.t(`general.alarm.type.${selectedAlarmType}`)})`}
        footer={null}
        centered
        width={800}
        open={isOpen}
        onCancel={onClose}
      >
        <Form
          layout="vertical"
          validateTrigger="onBlur"
          initialValues={undefined}
          autoComplete="off"
          onFinish={handleSubmit}
        >
          <div style={{ height: '8px' }} />
          <TopGridDiv>
            <Form.Item label={i18n.t('common.scope')}>
              <Radio.Group
                onChange={(event) => {
                  setSelectedScope(event.target.value);
                }}
                value={selectedScope}
              >
                <Space direction="horizontal">
                  {/*
                  <Radio
                    value={CONFIGURATION_SCOPE_LEVEL.platform}
                    disabled={isEdit || !!existingGlobalPlatformConfig}
                  >
                    {i18n.t('common.platform')}
                  </Radio>
                  */}
                  <Radio
                    value={CONFIGURATION_SCOPE_LEVEL.company}
                    disabled={isEdit || !!existingCurrentCompanyConfig}
                  >
                    {i18n.t('common.company')}
                  </Radio>
                  <Radio
                    value={CONFIGURATION_SCOPE_LEVEL.subsidiary}
                    disabled={isEdit || !!existingCurrentSubsidiaryConfig}
                  >
                    {i18n.t('common.subsidiary')}
                  </Radio>
                  <Radio value={CONFIGURATION_SCOPE_LEVEL.agent} disabled={isEdit}>
                    {i18n.t('common.agent')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {selectedScope >= CONFIGURATION_SCOPE_LEVEL.agent && (
              <Form.Item label={i18n.t('common.agent')}>
                <Select
                  loading={isLoading}
                  disabled={isEdit || !currentCompany?.id || !currentSubsidiary?.id}
                  options={agentOptions}
                  placeholder={i18n.t<string>('common.agent')}
                  value={selectedAgentId}
                  onChange={(value) => {
                    setSelectedAgentId(value);
                  }}
                />
              </Form.Item>
            )}
          </TopGridDiv>
          <Form.Item label={i18n.t('common.activated')}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Switch
                checked={formValues?.activated || false}
                loading={isLoading}
                onChange={(checked) => {
                  setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    activated: checked,
                  }));
                }}
              />
              <span
                style={{ color: formValues?.activated ? theme.colors.green : theme.colors.red }}
              >
                {getYesOrNo(!!formValues?.activated)}{' '}
                {`(${formValues?.activated ? i18n.t('common.active') : i18n.t('common.inactive')})`.toLowerCase()}
              </span>
            </div>
          </Form.Item>
          <BaseGridDiv>
            <ConfigurationToggleFormItem
              label={i18n.t('alarmConfigurations.level')}
              checked={enabledFormValues.level}
              onChange={(isChecked) => {
                setEnabledFormValues((prev) => ({ ...prev, level: isChecked }));
              }}
              isGlobal={isGlobal}
            >
              <Select
                placeholder={i18n.t('alarmConfigurations.level')}
                disabled={isLoading}
                value={formValues?.level || ALARM_LEVEL_OPTIONS?.at(0)?.value || undefined}
                options={ALARM_LEVEL_OPTIONS}
                onChange={(value) => {
                  setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    level: value,
                  }));
                }}
              />
            </ConfigurationToggleFormItem>
            <ConfigurationToggleFormItem
              label={i18n.t('alarmConfigurations.authority')}
              tooltipContent={
                <div>
                  {i18n.t('alarmConfigurations.tooltips.authority')}:<br />
                  <b>{ALARM_CONFIGURATION_AUTHORITY.Backend}:</b>{' '}
                  {i18n.t('alarmConfigurations.tooltips.authorityBackend')}
                  <br />
                  <b>{ALARM_CONFIGURATION_AUTHORITY.Device}:</b>{' '}
                  {i18n.t('alarmConfigurations.tooltips.authorityDevice')}
                </div>
              }
              checked={enabledFormValues.authority}
              onChange={(isChecked) => {
                setEnabledFormValues((prev) => ({ ...prev, authority: isChecked }));
              }}
              isGlobal={isGlobal}
            >
              <Select
                placeholder={i18n.t('alarmConfigurations.authority')}
                disabled={isLoading}
                value={formValues?.authority || ALARM_AUTHORITY_OPTIONS?.at(0)?.value || undefined}
                options={ALARM_AUTHORITY_OPTIONS}
                onChange={(value) => {
                  setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    authority: value,
                  }));
                }}
              />
            </ConfigurationToggleFormItem>
          </BaseGridDiv>
          {renderBooleanFormItem(topBooleanItems)}
          <div style={{ height: '4px' }} />
          {renderBooleanFormItem(canViewBooleanItems)}
          <div style={{ height: '4px' }} />
          {renderBooleanFormItem(canDismissBooleanItems)}
          <div style={{ height: '4px' }} />
          {renderBooleanFormItem(bottomBooleanItems)}
          {/*
          <ConfigurationToggleFormItem
            label={i18n.t('alarmConfigurations.triggerParameters')}
            checked={enabledFormValues.trigger_parameters}
            onChange={(isChecked) => {
              setEnabledFormValues((prev) => ({ ...prev, trigger_parameters: isChecked }));
            }}
            isGlobal={isGlobal}
          >
            <Input.TextArea
              style={{
                fontFamily: 'monospace',
                borderColor: hasTriggerParametersError ? theme.colors.red : undefined,
              }}
              rows={3}
              placeholder={DEFAULT_ALARM_CONFIGURATION.trigger_parameters}
              disabled={isLoading}
              defaultValue={DEFAULT_ALARM_CONFIGURATION.trigger_parameters}
              value={formValues?.trigger_parameters || undefined}
              required={enabledFormValues.trigger_parameters}
              onChange={(event) => {
                setFormValues((prevFormValues) => ({
                  ...prevFormValues,
                  trigger_parameters: event.target.value,
                }));
              }}
            />
          </ConfigurationToggleFormItem>
          */}
          <BottomActionsDiv>
            <Button size="middle" disabled={isLoading} onClick={onClose}>
              {i18n.t('common.cancel')}
            </Button>
            <Button
              size="middle"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              icon={<SaveOutlined />}
              disabled={!isGlobal && enabledCount === 0}
            >
              {isEdit ? i18n.t('common.save') : i18n.t('common.create')}
              {isGlobal ? '' : overridesCountTranslation}
            </Button>
          </BottomActionsDiv>
        </Form>
      </Modal>
    );
  },
);

AlarmConfigurationAddEditModal.displayName = 'AlarmConfigurationAddEditModal';

export default AlarmConfigurationAddEditModal;
