import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { Checkbox, Form, Tooltip } from 'antd';
import { memo, ReactNode, useEffect, useState } from 'react';

interface Props {
  children: ReactNode;
  label: ReactNode;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  isGlobal: boolean;
  disabled?: boolean;
  tooltipContent?: ReactNode;
}

const ConfigurationToggleFormItem = memo(
  ({ children, label, checked, onChange, isGlobal, disabled, tooltipContent }: Props) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    useEffect(() => {
      if (isGlobal) {
        setIsChecked(true);
      }
    }, [isGlobal]);

    return (
      <Form.Item>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Checkbox
            style={{ cursor: isGlobal ? 'default' : 'pointer' }}
            checked={isChecked}
            onChange={(event) => {
              if (!isGlobal) {
                onChange(event.target.checked);
              }
            }}
            disabled={disabled}
          >
            {label}
          </Checkbox>
          {tooltipContent && (
            <Tooltip title={tooltipContent} placement="right">
              <span style={{ cursor: 'help', opacity: disabled ? 0.25 : 1 }}>
                <InfoCircleOutlined />
              </span>
            </Tooltip>
          )}
        </div>
        <div
          style={{
            pointerEvents: isChecked ? 'auto' : 'none',
            opacity: isChecked ? 1 : 0.25,
          }}
        >
          {children}
        </div>
      </Form.Item>
    );
  },
);

ConfigurationToggleFormItem.displayName = 'ConfigurationToggleFormItem';

export default ConfigurationToggleFormItem;
